import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Box, Heading, HStack, Text } from "@chakra-ui/react";
import Layout from "../components/Layout";
import Tech from "../components/Tech";
import Seo from "../components/seo";
import ContactForm from "../components/ContactForm";
import Container from "../components/ui/Container";

function CloudSolution() {
  return (
    <Layout>
      <Seo
        title="BITDECODERS | CLOUD SOLUTIONS CONSULTANCY IN INDIA | WE WORK"
        meta={[
          {
            name: "keywords",
            content:
              "cloud consultancy, aws consulting, cloud consulting services, cloud computing consulting, azure consultant, cloud consulting companies, cloud computing consulting services, aws consulting services, azure consulting services, google cloud consultant, aws cloud consulting services, microsoft azure consultant, google cloud consultancy, aws consultancy, aws consulting companies, microsoft azure consulting services, azure cloud consultant",
          },
          {
            name: "description",
            content:
              "We specialize in providing cloud solution consuultancy, and web designing and development services including solution integration and offshore IT outsourcing to small and medium-sized businesses (SMB) across the globe. Go with us and you can benefit from our experience, with an amazing range of skills, deep vertical industries expertise, and excellence in top technologies, like Html, Html5, Node JS, React JS, Next JS, Gatsby, Swift, Kotlin, Flutter, Data Base Management, and various other custom application fields.",
          },
        ]}
      />

      <Box position="relative">
        <StaticImage
          src="../images/third-party-header.png"
          alt="Bitdecoders Third party banner image"
          layout="fullWidth"
        />

        <Heading
          as="h1"
          fontSize={{ base: "lg", md: "3xl", lg: "5xl" }}
          fontWeight="bold"
          p={{ base: "4", md: "6", lg: "8" }}
          position="absolute"
          left="0"
          bottom={{ base: "0.5", md: "4", lg: "10" }}
        >
          <HStack>
            <Text color="#f4164a">CLOUD</Text>
            <Text color="white">WE WORK</Text>
          </HStack>
          <Box fontSize={{ base: "xs", md: "sm", lg: "md" }} fontWeight="light">
            <Text color="white">
              Let&apos;s talk about what we can build together
            </Text>
          </Box>
        </Heading>
      </Box>

      <Container fluid>
        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 mx-1">
            <div className="px-10 pt-6 text-xl space-y-5 text-left">
              <div>
                <p>
                  We specialize in{" "}
                  <span className="font-bold">
                    Amazon Web Services, Google Cloud &amp;
                  </span>
                  <span className="font-bold">Microsoft Azure.</span> Cloud
                  development is the process of creating applications or
                  computing solutions that can be operated entirely on web-based
                  platforms. It provides enhanced flexibility to build and
                  deploy new applications as compared to in-house software
                  development. More specifically, it&apos;s all of the things
                  you can access remotely over the Internet. When something is
                  in the cloud, it means it&apos;s stored on Internet servers
                  instead of your computer&apos;s hard drive.
                </p>
              </div>
              <div>
                <p>
                  Go with us and you can benefit from our experience, with an
                  amazing range of skills, deep vertical industries expertise,
                  and excellence in top technologies.
                </p>
              </div>
            </div>
            <div className="relative mt-8 md:w-3/4 md:-top-36">
              <ContactForm />
            </div>
          </div>
          <div className="text-center">
            <h3 className="text-2xl font-bold">
              <span className="text-black">CLOUD </span>
              <span className=" text-red-500">SOLUTIONS</span>
            </h3>
            <p>We provide best cloud consulation and cloud solution</p>
          </div>

          {/* servers section  */}

          <div className="mx-20 pt-6 grid grid-cols-1 sm:grid-cols-3 gap-10">
            <div className="bg-white border-2 shadow-md rounded-lg text-center px-4 py-4">
              <StaticImage
                className=" w-1/2 "
                src="../images/Amazon_Web_Services.png"
                alt="Bitdecoders aws icon"
              />
              <div className="font-bold pt-10 text-black">
                <h4>Amazon Web Services</h4>
              </div>
            </div>

            <div className="bg-white border-2 shadow-md rounded-lg text-center px-4 py-4">
              <StaticImage
                className="w-1/2"
                src="../images/Google_Cloud.png"
                alt="Bitdecoders google cloud icon"
              />
              <div className="font-bold pt-1 text-black">
                <h4>Google Cloud</h4>
              </div>
            </div>
            <div className="bg-white border-2 rounded-lg shadow-md text-center px-4 py-4">
              <StaticImage
                className="w-1/2"
                src="../images/Microsoft_Azure.png"
                alt="Bitdecoders microsoft azure icon"
              />
              <div className="font-bold pt-10 text-black">
                <h4>Microsoft Azure</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="my-16">
          <Tech />
        </div>
      </Container>
    </Layout>
  );
}

export default CloudSolution;
